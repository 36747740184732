.App {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  border-radius: 5px 5px 0 0;
}

.chat-container {
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.message {
  margin: 10px 0;
}

.message-user {
  text-align: right;
}

.message-system {
  text-align: left;
}

.message-content {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 18px;
  max-width: 70%;
  word-break: break-word;
}

.message-user .message-content {
  background-color: #007bff;
  color: white;
}

.message-system .message-content {
  background-color: #28a745;
  color: white;
}

.input-form {
  display: flex;
}

.input-field {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccc;
  border-right: none;
}

.submit-button {
  width: 80px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.message-content {
  white-space: pre-wrap;
  word-break: break-word;
}

del {
  text-decoration: line-through;
  color: #990000;
}

strong em {
  font-style: italic;
  font-weight: bold;
  color: #FF0;
}